import React from "react";
import { useState } from "react";
import styles from "./Navbar.module.css";
import LOGO from "../assets/chaskka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Navbar = (props) => {
  // adding the states
  const [isActive, setIsActive] = useState(false);
  const [language, setLanguage] = useState(true);

  const navVal = props.page;

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  return (
    <header className="App-header">
      <div className={`${styles.logo}`}>
        <Link to={"/"}>
          <img src={LOGO} alt="Logo" className="App-logo" />{" "}
        </Link>
      </div>
      <nav className={`${styles.navbar}`}>
        <ul className={`${styles.navMenu} ${isActive ? styles.active : ""}`}>
          <Link to={"/"}>
            <li className={navVal === "home" ? "selectedMenu" : "navLink"}>
              <span>HOME</span>
            </li>
          </Link>
          <Link to={"/our-spice-story"}>
            <li className={navVal === "story" ? "selectedMenu" : "navLink"}>
              <span>OUR SPICE STORY</span>
            </li>
          </Link>
          <Link to={"https://order.ninito.no/en/group/chaskka/oslo"}>
            <li className={navVal === "menu" ? "selectedMenu" : "navLink"}>
              <span>MENU</span>
            </li>
          </Link>

          <Link to={"/contact-us"}>
            <li className={navVal === "contact" ? "selectedMenu" : "navLink"}>
              <span>CONTACT US</span>
            </li>
          </Link>
        </ul>
        <div className="social">
          <div>
            <Link to={"https://www.facebook.com/profile.php?id=61559028831241"}>
            <FontAwesomeIcon
              icon={faSquareFacebook}
              style={{ color: "#a77541", paddingRight: "5px" }}
              className="hover-scale"
            />
            </Link>
            <Link to={"https://www.instagram.com/chaskkafrogner?igsh=MXh3ZnU3ZHB2b3ZnbA=="}>
            <FontAwesomeIcon
            icon={faSquareInstagram}
            style={{ color: "#a77541", paddingLeft: "6px"}}
            className="hover-scale"
          />
          </Link>
          </div>
          
          <div>
            <span
              className={`lang ${language ? "langActive" : ""}`}
              style={{ fontSize: "0.8em" , paddingLeft: "-1px", }}
              onClick={() => setLanguage(true)}
            >
              EN
            </span>
            <span
              style={{ fontSize: "0.9em" }}
            >
              |
            </span>
            <span
              className={`lang  ${language ? "" : "langActive"}`}
              style={{ fontSize: "0.8em" }}
              onClick={() => setLanguage(false)}
            >
              NO
            </span>
          </div>
        </div>
        <div
          className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
          onClick={toggleActiveClass}
        >
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
