import { RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import MenuPage from './pages/MenuPage';
import StoryPage from './pages/StoryPage';
import ContactPage from './pages/ContactPage';
import PageNotFound from './pages/PageNotFound';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

 const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <HomePage></HomePage>
    )
  },
  {
    path: '/menu',
    element: (
      <MenuPage></MenuPage>
    )
  },
  {
    path: '/our-spice-story',
    element: (
      <StoryPage></StoryPage>
    )
  },
  {
    path: '/contact-us',
    element: (
      <ContactPage></ContactPage>
    )
  },
  
  {
    path: '*',
    element: (
      <PageNotFound></PageNotFound>
    )
  },
 ]);

 ReactGA.initialize("G-LPN82WKDK4");
function App() {
  // const location = useLocation();

  useEffect(() => {
    ReactGA.send({hitType:"page_view", page: window.location.pathname});
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
