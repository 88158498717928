import React from "react";
import ChaskkaVideo from "../assets/chaskka.mp4";
import ChaskkaVideo1 from "../assets/video.mp4";
import Wolt from "../assets/wolt.png";
import Foodora from "../assets/foodora.png";
import Pickup from "../assets/pickup.png";
import Char2 from "../assets/character.png";
import Collage1 from "../assets/collage.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <video src={ChaskkaVideo} autoPlay loop muted></video>
      <div className="text1"> Your hunger partner</div>
      <div className="text2">
        Order for pick up or get delivered right to your door!
      </div>
      <div className="character">
        <img src={Char2} alt="" className="charImg" />
        <div className="deliveryPartners">
          <Link to={"https://order.ninito.no/en/group/chaskka/oslo"}>
            <div className="chaskka hover-scale">
              <img src={Pickup} alt="Bag of food" />
              <span className="partner">Pickup</span>
            </div>
          </Link>
          <Link to={"https://wolt.com/en/nor/oslo/restaurant/chaskka"} target="_blank">
            <div className="wolt hover-scale">
              <img src={Wolt} alt="Bag of food" />
              <span className="partner">Wolt</span>
            </div>
          </Link>
          <Link to={"https://www.foodora.no/restaurant/y774/chaskka"} target="_blank">
            <div className="foodora hover-scale">
              <img src={Foodora} alt="Bag of food" />
              <span className="partner">Foodora</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="introduction">
        <div className="intro-title">Chaskka</div>
        <div className="text3">
          Welcome to Chaskka; where the aroma of good food fills the air and
          creates unforgettable moments with your loved ones. At our kitchen, we
          believe that the secret ingredient to a joyful heart lies in the love
          and care we infuse into every dish. With our meticulously crafted
          in-house spices, we aim to elevate your food experience to new
          heights. Each spice blend is a testament to our dedication to
          authenticity and flavor, ensuring that every bite is a celebration of
          taste and tradition. Join us on a journey to discover the irresistible
          allure of our spices. Let us ignite your senses and cultivate a
          'Chaskka'~ an obsession; a craving for our unique culinary story.
        </div>
        <div className="collage">
          <img src={Collage1} alt="" />
        </div>
      </div>

      <div className="short">
        <video
          src={ChaskkaVideo1}
          autoPlay
          loop
          muted
          className="video"
        ></video>

        <div className="text4">
          <div className="text"> #chaskka</div>
          Embark on a flavorful journey with us as we delve into the rich
          tapestry of Indian spice tradition. Our story began with a quest for
          the genuine essence of blended spices, inspired by the age-old Indian
          practice of crafting unique blends at home. At the heart of our
          journey lies our signature Tikka Masala blend ~ a harmonious fusion of
          carefully selected spices that ignite the palate and inspire culinary
          creativity. From the aromatic depths of our Biryani Masala to the
          velvety richness of our Butter Chicken Masala, each blend is a tribute
          to the artistry of Indian cuisine.
        </div>
      </div>
    </div>
  );
};

export default Home;
